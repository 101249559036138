import React from "react";

const Privacy = (props) => {
  return (
    <div class="article">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Mentions légales</h1>
            <div class="col-xs-12">
              <h2>1. Présentation du site.</h2>
              <p>
                <strong>Propriétaire :</strong> Davoyage <br />
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
