import React from "react";

import TermsFR from "../components/terms/Terms-fr";
import TermsEn from "../components/terms/Terms-en";
import TermsRu from "../components/terms/Terms-ru";

const Terms = (props) => {
  if(props.lang==='fr'){
    return (
      <TermsFR lang={props.lang}/>
    );
  } else if(props.lang==='en'){
    return (
      <TermsEn lang={props.lang}/>
    );
  } if(props.lang==='ru'){
    return (
      <TermsRu lang={props.lang}/>
    );
  }
};

export default Terms;
