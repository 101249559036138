import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import axios from "axios";
import { DiscussionEmbed } from 'disqus-react';
import config from "../config";
import { Helmet } from 'react-helmet';
import translations from "../translations/blog";

const Article = (props) => {
  
  const { slug } = useParams();
  const [article, setArticle] = useState([]);
  const articlePath = props.lang === "fr" ? "/blog/" : "/" + props.lang + "/blog/";

  useEffect(() => {
    axios
      .get(
          config["api-endpoint"] + "/articles/" +
          slug +
          "?lang=" +
          props.lang,
        {
          headers: {
            "x-api-key": config["x-api-key"],
          },
        }
      )
      .then((res) => {
        let article;
        res.data.translations.forEach((translation) => {
          if (translation.lang === props.lang) {
            article = translation;

            const date = new Date(article.createdAt);
            article.createdYear = date.getFullYear();
            article.createdMonth = (date.getMonth()<9 ? "0"+ (date.getMonth()+1) : date.getMonth()+1);
            article.createdDay = (date.getDate()<10 ? "0"+date.getDate() : date.getDate());
      
            article._id = res.data._id;
          }
        });
        setArticle(article);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug, props.lang]);

  return (
    <div
      className="article"
      itemScope=""
      itemType="http://schema.org/BlogPosting"
    >
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.metaDescription}/>
        <meta property="og:title" content={article.title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content={"https://www.davoyage.com" + articlePath + article.slug}/>
        <meta property="og:image" content={article.imageLink} />
        <meta property="og:image:alt" content={article.title}/>
        <meta property="og:description" content={article.metaDescription}/>
        <meta property="og:site_name" content="Davoyage"/>
        <meta property="article:published_time" content={article.createdAt}/>
        <link rel="canonical" href={"https://www.davoyage.com" + articlePath + article.slug} />
      </Helmet>
      <meta
        itemScope=""
        itemProp="mainEntityOfPage"
        itemType="https://schema.org/WebPage"
        itemID={articlePath + article.slug}
      />
      {article.slug !== undefined && (
        <main role="main" className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10 blog-main">
              <div
                itemScope
                itemType="https://schema.org/BreadcrumbList"
                className="breadcrumb mt-3 mb-1"
              >
                <span itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                  <Link to={articlePath} itemProp="item">
                    <span itemProp="name">Blog</span>
                  </Link>
                  <meta itemProp="position" content="1" />
                </span>
                &nbsp;»&nbsp;
                <div
                  className="breadcrumb-child"
                  itemProp="itemListElement" itemScope
                  itemType="https://schema.org/ListItem"
                >
                  <Link to={articlePath + article.slug} itemProp="item">
                    <span itemProp="name">{article.title}</span>
                  </Link>
                  <meta itemProp="position" content="2" />
                </div>
              </div>
              <h1 className="pb-3 mb-4 pt-1 border-bottom" itemProp="headline">
                {article.title}
              </h1>
              <div className="blog-post">
                <img
                  itemProp="image"
                  src={article.imageLink}
                  alt={article.imageAlt}
                  className="img-fluid"
                />
                {article.imageBy !== undefined && (
                  <small>{translations[props.lang].photoBy} <a href={article.imageBy.url} target="_blank" rel="noreferrer">{article.imageBy.name}</a> {translations[props.lang].photoOn} <a href={article.imageOn.url} target="_blank" rel="noreferrer">{article.imageOn.name}</a></small>
                )}
                <p
                  itemProp="articleBody"
                  dangerouslySetInnerHTML={{ __html: article.content }}
                  className="pt-3"
                ></p>
                <p className="blog-post-meta">Publié le {article.createdDay+"/"+article.createdMonth+"/"+article.createdYear}</p>
                <meta itemProp="datePublished" content={article.createdYear+"-"+article.createdMonth+"-"+article.createdDay} />
                <meta itemProp="author" content="Davoyage" />
                <div
                  itemProp="publisher"
                  itemScope=""
                  itemType="https://schema.org/Organization"
                >
                  <meta itemProp="name" content="Davoyage" />
                  <div
                    itemProp="logo"
                    itemScope=""
                    itemType="https://schema.org/ImageObject"
                  >
                    <meta
                      itemProp="url"
                      content="https://res.cloudinary.com/hfguibnid/c_scale,w_auto,dpr_auto/d-davoyage32x32.png"
                    />
                  </div>
                </div>
              </div>
              <DiscussionEmbed
                shortname='Davoyage'
                config={
                    {
                        url: 'https://www.davoyage.com' + articlePath + article.slug,
                        identifier: article._id,
                        title: article.title,
                        language: props.lang
                    }
                }
              />
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default Article;
