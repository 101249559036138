module.exports = {
  fr: {
    selected: {
      label: "Français",
      code: "fr",
    },
    quote: {
      label: "Devis",
      url: "/devis-reservation-vtc/",
    },
    home: {
      label: "Accueil",
      url: "/",
    },
    blog: {
      label: "Blog",
      url: "/blog/",
    },
    phone: "06 60 26 53 36",
    brand: {
      label: "Davoyage",
      url: "/",
    },
  },
  en: {
    selected: {
      label: "English",
      code: "en",
    },
    quote: {
      label: "Quotes",
      url: "/en/devis-reservation-vtc/",
    },
    home: {
      label: "Home",
      url: "/en/",
    },
    blog: {
      label: "Blog",
      url: "/en/blog/",
    },
    phone: "+33 660 265 336",
    brand: {
      label: "Davoyage",
      url: "/en/",
    },
  },
  ru: {
    selected: {
      label: "Pусский",
      code: "ru",
    },
    quote: {
      label: "котировка",
      url: "/ru/devis-reservation-vtc/",
    },
    home: {
      label: "дома",
      url: "/ru/",
    },
    blog: {
      label: "блог",
      url: "/ru/blog/",
    },
    phone: "+33  660 265 336",
    brand: {
      label: "Davoyage",
      url: "/ru/",
    },
  },
};
