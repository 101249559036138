module.exports = {
    fr: {
        summary: "Récapitulatif de votre demande",
        title: "Référence :",
        startPlace: "Lieu de prise en charge :",
        startDate: "Date et heure de départ :",
        passengers: "Passager :",
        bagages: "Bagages :",
        toPlace: "Destination :",
        toDate: "Date et heure du retour :",
        price: "Prix :",
    },
    en: {
        summary: "Summary of your request",
        title: "Reference:",
        startPlace: "Pickup location:",
        startDate: "Date and time of departure:",
        passengers: "Passager:",
        bagages: "Bagages:",
        toPlace: "Destination:",
        toDate: "Date and time of return:",
        price: "Price:",
    },
    ru: {
        head: "Мы получили ваш запрос.",
        subhead: "Мы благодарим вас за доверие.<br/>Ваша цитата находится в стадии подготовки.<br/>Вы получите в течение нескольких минут предложение по электронной почте на ваш адрес:",
        summary: "Сводная информация о вашем запросе",
        title: "ссылка:",
        startPlace: "ссылка:",
        startDate: "Дата и время отправления:",
        passengers: "Passager:",
        bagages: "Bagages:",
        toPlace: "назначения:",
        toDate: "Дата и время возвращения:",
        price: "Price:",
    }
  };
  