import React from "react";

const Terms = (props) => {
  return (
    <div class="article">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Commercial Terms</h1>
            <div class="col-xs-12">
              <h2>Booking</h2>
              <p>
                A preliminarybooking by phone or email is required for any
                service. The company will send an estimate to the customer. The
                booking becomes contractual when the customer has returned the
                signed estimate by mail or email.
              </p>
              <p>
                Email : david.imirzian@davoyage.com
                <br />
                Phone number : +33 6 60 26 53 36
              </p>
              <p>
                A minimum deposit of 25% of the cost of the service may be
                requested at booking if it is considered important by the
                driver. The remaining will be paid before the departure of the
                vehicle.
              </p>
              <p>
                The Company shall have the right to suspend or cancel a
                reservation in case of major forces.
              </p>
              <h2>Responsibility</h2>
              <p>
                The driver can not be held responsible for any damages caused by
                the density of traffic.
              </p>
              <p>
                The driver must respect the traffic rules. The customer can not
                ask the driver to violate the traffic rules. Wearing seat belts
                is compulsory. The driver can not be held responsible for the
                consequences of not wearing a seatbelt (ticket, accident).
              </p>
              <p>
                The vehicle is non-smoking. The driver may stop during the ride
                to enable the customer to smoke outside the vehicle.
              </p>
              <p>
                It is absolutely forbidden to consume alcohol or other drugs in
                the vehicle.
              </p>
              <p>
                Respect of equipment is needed: any damage of the equipment will
                be charged. The degradation of inside equipment or other damage
                to the vehicle or to other vehicles will be charged to the
                client.
              </p>
              <p>
                Only the driver could open the door. Any unintended opening
                causing damage to the vehicle will be charged to the client.
              </p>
              <p>
                The last minute changes or amendments ride may be refused by the
                company or result in a rate change.
              </p>
              <p>Any service started must be paid.</p>
              <p>
                In case of failure, the invoice will be calculated
                proportionally to the service provided. The driver can not be
                held responsible for any damages caused by the failure.
              </p>
              <h2>Pricing</h2>
              <p>
                Prices are set before delivery and are independent of traffic.
              </p>
              <p>
                All additional fees (tolls, parking, etc.) not agreed in advance
                will be charged in addition to the client.
              </p>
              <p>
                A rate increase of 30% will be applied for all courses taking
                place between 20:00 and 07:00 am.
              </p>
              <h2>Payment</h2>
              <p>
                The methods of payment accepted are: cash, credit card (Visa,
                Master Card) and bank transfer.
              </p>
              <h2>Cancellation</h2>
              <p>
                Cancellation 72 hours before: free
                <br />
                Cancellation from 24 to 48 hours before: 50% of the benefit
                <br />
                Cancellation from departure time to 24 hours : 100% of the
                benefit
              </p>
              <p>
                Reservations not canceled after the departure of the vehicle
                will be charged at the current rate.
              </p>
              <h2>Luggages</h2>
              <p>
                Luggage or other property of the customer remain under its
                responsibility. Our company can not be held responsible in case
                of theft or loss.
              </p>
              <p>
                The company accepts 20kg of luggage per person. If exceeded,
                please inform the driver during the booking. Otherwise, we
                reserve the right to charge extra for out of bounds luggage.
              </p>
              <p>
                If luggage damage the vehicle, the customer will be responsible
                for repairs.
              </p>
              <h2>Pets</h2>
              <p>
                No pets are allowed in the vehicle, except in a cage or in a
                sealed container for this purpose and approved by the driver.
              </p>
              <h2>Interruption of service</h2>
              <p>
                The driver reserves the right to suspend if he considers that
                the passenger behavior is contrary to the laws or insulting him.
              </p>
              <p>
                The driver reserves the right to interrupt the race if the
                passenger threatens the safety of the driver or the vehicle.
              </p>
              <p>No refund or compensation will be demanded.</p>
              <h2>VAT</h2>
              <p>The rates in effect will apply.</p>
              <p>
                For transfers, the VAT rate is 10%. A transfer is defined as the
                move from point A to point B.
                <br />
                For the provision, the VAT rate is 20%. A provision is defined
                as a custom trip.
              </p>
              <h2>Issue</h2>
              <p>
                In case of dispute, it will be subject to the exclusive
                jurisdiction of the court of Lyon.
              </p>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;