import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

import config from "../config";

import Steps from "../components/steps/Steps";
import QuoteDetail from "../components/quoteDetail/QuoteDetail";
import CheckoutForm from "../components/checkoutForm/CheckoutForm";

import translations from "../translations/paiement";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(config["stripe-public-key"]);

const Paiement = (props) => {
  let [searchParams] = useSearchParams(),
    id = searchParams.get("id"),
    hash = searchParams.get("hash"),
    [quote, setQuote] = useState([]),
    [options, setOptions] = useState([]),
    navigate = useNavigate()
  ;

  document.title = translations[props.lang].meta.title;

  useEffect(() => {
    if(id===null||hash===null){
      navigate((props.lang==='fr'?'/':'/'+props.lang));
      return;
    }
    axios
      .get(
        config["api-endpoint"] + "/quotes/" + id + "/?paiement_hash=" + hash,
        {
          headers: {
            "x-api-key": config["x-api-key"],
          },
        }
      )
      .then((res) => {
        if(res.status===404 || res.data.errorType === 'BSONTypeError'){
          navigate((props.lang==='fr'?'/':'/'+props.lang));
        }
        if(res.status===502){
          navigate((props.lang==='fr'?'/':'/'+props.lang));
        }
        if (res.data.status === "Fermé / Gagné") {
            navigate((props.lang==='fr'?'':'/'+props.lang)+"/devis-reservation-vtc/success");
        }

        res.data.id = res.data._id.substring(0, 7);
        var domain = res.data.email.split("@")[1][0];
        var dI = res.data.email.split("@")[1].split(".")[0].length;
        for (var i = 0; i < dI - 1; i++) {
          domain += "*";
        }
        domain += "." + res.data.email.split("@")[1].split(".")[1];
        var user = res.data.email.split("@")[0][0];
        var uI = res.data.email.split("@")[0].length;
        for (var j = 0; j < uI - 1; j++) {
          user += "*";
        }

        res.data.emailObs = user + "@" + domain;
        setQuote(res.data);
      })
      .catch((err) => {
        console.error(err);
        navigate((props.lang==='fr'?'':'/'+props.lang));
      });

    axios
      .get(
        config["api-endpoint"] +
          "/quotes/" +
          id +
          "/payment-intent/?paiement_hash=" +
          hash,
        {
          headers: {
            "x-api-key": config["x-api-key"],
          },
        }
      )
      .then((res) => {
        console.log(res.status)
        if(res.status===502){
          navigate((props.lang==='fr'?'/':'/'+props.lang));
        }
        setOptions({
          clientSecret: res.data.clientSecret,
          locale: props.lang,
        });
      })
      .catch((err) => {
        console.error(err);
        navigate((props.lang==='fr'?'':'/'+props.lang));
      });
  }, [id, hash, props.lang, navigate]);

  return (
    <div className="quote">
      <Steps active="2" />
      <div className="container">
        <div className="pt-4 pb-2 text-center">
          <h2>{translations[props.lang].title}</h2>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 col-sm-12">
            {quote !== undefined &&
              quote !== null &&
              quote.hasOwnProperty("email") && (
                <QuoteDetail lang={props.lang} quote={quote} />
              )}
          </div>
        </div>
        {options.clientSecret !== undefined && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm lang={props.lang} quote={quote} />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default Paiement;
