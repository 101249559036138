module.exports = {
    fr: {
        meta: {
            title: "Blog de Davoyage : découvrez les dernières tendances en matière de transport privé",
            description: "Découvrez les dernières tendances en matière de transport privé sur le blog de Davoyage, votre partenaire de confiance pour vos déplacements professionnels ou personnels. Retrouvez des conseils, des astuces et des actualités sur notre blog pour voyager en toute sérénité.",    
        },
        og: {
            image: {
                url: "https://res.cloudinary.com/hfguibnid/c_scale,w_auto,dpr_auto/mercedes-class-v-ext.jpg",
                alt: ""
            }
        },
        head: "Blog Davoyage",
        photoBy: "Photo par",
        photoOn: "sur"
    },
    en: {
        meta: {
            title: "Davoyage Blog - Stay up-to-date with the latest travel tips and trends",
            description: "Stay informed with Davoyage Blog, your source for travel tips, trends, and news. From insider knowledge to destination guides, we've got you covered.",    
        },
        og: {
            image: {
                url: "https://res.cloudinary.com/hfguibnid/c_scale,w_auto,dpr_auto/mercedes-class-v-ext.jpg",
                alt: ""
            }
        },
        head: "Blog Davoyage",
        photoBy: "Photo by",
        photoOn: "on"
    },
    ru: {
        meta: {
            title: "Блог Davoyage - Будьте в курсе последних советов и тенденций в путешествиях",
            description: "Будьте в курсе событий с блогом Davoyage, вашим источником советов, тенденций и новостей в сфере путешествий. От экспертной информации до путеводителей, мы следим за всеми новинками.",
        },
        og: {
            image: {
                url: "https://res.cloudinary.com/hfguibnid/c_scale,w_auto,dpr_auto/mercedes-class-v-ext.jpg",
                alt: ""
            }
        },
        head: "Blog Davoyage",
        photoBy: "фото",
        photoOn: "на"
    }
};