module.exports = {
    fr: {
        title: "Obtenez un devis pour votre transport privé",
        description: "Profitez d'un service de transport de qualité supérieure, fiable et sécurisé. Après avoir rempli le formulaire, vous recevrez notre proposition par email dans les plus brefs délais. Pour toute demande de mise à disposition, contactez-nous directement par téléphone ou par email à l'adresse suivante :"
    },
    en: {
        title: "Request a price quote",
        description: "Book your private driver in a few clicks. You will receive very quickly by email our proposal to accompany you in your trips to Lyon and its surroundings. For all requests for availability, please contact us directly by phone or by email at:"
    },
    ru: {
        title: "Запрос цены",
        description: "Забронируйте свой частный драйвер за несколько кликов. Вы получите очень быстро по электронной почте наше предложение, чтобы сопровождать вас в своих поездках в Лион и его окрестности. Для получения всех запросов о доступности свяжитесь с нами напрямую по телефону или по электронной почте"
    }
};