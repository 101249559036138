module.exports = {
    fr: {
        title: "Paiement effectué avec succès",
        subtitle: "Nous vous remercions pour votre confiance.",
        description: "Vous allez prochainement recevoir un appel de votre chauffeur afin de préparer ensemble le déplacement. Pour toutes questions, vous pouvez également nous contacter par email à l'adresse contact@davoyage.com mais également par téléphone au 06 60 26 53 36.",
        soon: "À très bientôt.",
        home: {
            label: "Revenir sur le site internet",
            link: "/"
        }
    },
    en: {
        title: "Payment made successfully",
        subtitle: "We thank you for your confidence.",
        description: "You will soon receive a call from your driver to prepare the trip together. For any questions, you can also contact us by email at contact@davoyage.com or by phone at 06 60 26 53 36.",
        soon: "See you soon",
        home: {
            label: "Return to the website",
            link: "/en/"
        }
    },
    ru: {
        title: "Оплата выполнена успешно",
        subtitle: "Мы благодарим вас за доверие.",
        description: "Вскоре вам позвонит водитель, чтобы вместе подготовиться к поездке. По любым вопросам вы также можете связаться с нами по электронной почте contact@davoyage.com, а также по телефону 06 60 26 53 36.",
        soon: "До скорого",
        home: {
            label: "Вернуться на сайт",
            link: "/ru/"
        }
    }
};