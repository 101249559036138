import { Outlet } from "react-router-dom";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const Layout = (props) => {
  return (
    <>
      <Header lang={props.lang}/>
      <Outlet />
      <Footer lang={props.lang}/>
    </>
  )
};

export default Layout;