import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Recaptcha from "../recaptcha/Recaptcha";
import Autocomplete from "../autocomplete/Autocomplete";

import config from "../../config";
import styles from "./QuoteForm.module.css"
import translations from "./quote-form-translations";

const QuoteForm = (props) => {

    const navigate = useNavigate();

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth()<9 ? "0"+(date.getMonth()+1) : date.getMonth()+1);
    const day = (date.getDate()<10 ? "0"+date.getDate() : date.getDate());
    const today = year+"-"+month+"-"+day;

    const [quote, setQuote] = useState({
        voyage: translations[props.lang].voyage.options[0].value,
        passenger: "1",
        bagages: "0",
        vehicule: ['berline','van','minibus'].includes(props.vehicule) ? props.vehicule : 'van',
        date: today,
        hour: "10",
        minute: "00",
        return_date: today,
        return_hour: "11",
        return_minute: "00"
    });
  
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setQuote(values => ({...values, [name]: value}))
    }

    const allowedFields = [
      "address_depart",
      "address_depart_number",
      "address_depart_city",
      "address_depart_address",
      "address_depart_cp",
      "address_depart_country",
      "address_depart_lng",
      "address_depart_lat",
      "address_arrive",
      "address_arrive_number",
      "address_arrive_city",
      "address_arrive_address",
      "address_arrive_cp",
      "address_arrive_country",
      "address_arrive_lng",
      "address_arrive_lat",
      "voyage",
      "date",
      "hour",
      "minute",
      "return_date",
      "return_hour",
      "return_minute",
      "vehicule",
      "passenger",
      "bagages",
      "firstname",
      "lastname",
      "email",
      "phone",
      "g-recaptcha-response",
    ];
    const intFields = ["passenger", "bagages"];
    const floatFields = [
      "address_depart_lng",
      "address_depart_lat",
      "address_arrive_lng",
      "address_arrive_lat",
    ];
    const cleaning = () => {
      if (quote.voyage === "simple") {
        delete quote.return_date;
        delete quote.return_hour;
        delete quote.return_minute;
      }
      for (var a in quote) {
        if (!allowedFields.includes(a)) {
          delete quote[a];
        } else if (intFields.includes(a)) {
          quote[a] = isNaN(parseInt(quote[a])) ? parseInt(quote[a]) : quote[a];
        } else if (floatFields.includes(a)) {
          quote[a] = isNaN(parseInt(quote[a])) ? parseFloat(quote[a]) : quote[a];
        } else if (typeof quote[a] != "string" || quote[a] === "" || quote[a] === null) {
          delete quote[a];
        }
      }
    }

    const switchSubmitButton = () => {
      document.getElementById('submit').disabled = !document.getElementById('submit').disabled;
    }

    const handleSubmit = (event) => {
      event.preventDefault();

      switchSubmitButton();
      
      cleaning();

      axios
      .post(config["api-endpoint"] + "/quotes/", quote, {
        headers: {
          "x-api-key": config['x-api-key'],
        },
      })
      .then((res) => {
        if(res.status===404){
          navigate((props.lang==='fr'?'':'/'+props.lang));
        }
        if(res.status===200){
          navigate(
            (props.lang==='fr'?'':'/'+props.lang)+"/devis-reservation-vtc/confirmation",
            {
              state : quote,
              replace:true
            }
          );
        }
        if(res.status===400){
          if(res.data.error==='Recaptcha Error'){
            document.getElementById('FormError').innerHTML = translations[props.lang].error.recaptcha;
          } else if (res.data.error ==='Quote Not Inserted'){
            document.getElementById('FormError').innerHTML = translations[props.lang].error.form;
          }
          switchSubmitButton();
        }
      })
      .catch((error) => {
        document.getElementById('FormError').innerHTML = translations[props.lang].error.form;
        switchSubmitButton();
      })
    }
  
    const setMyToken = (token) => {
      quote['g-recaptcha-response']=token;
    }

    var mapping = {
      depart: {
        formatted_address: "address_depart",
        street_number: "address_depart_number",
        route: "address_depart_address",
        locality: "address_depart_city",
        country: "address_depart_country",
        postal_code: "address_depart_cp",
        lat: "address_depart_lat",
        lng: "address_depart_lng",
      },
      arrive: {
        formatted_address: "address_arrive",
        street_number: "address_arrive_number",
        route: "address_arrive_address",
        locality: "address_arrive_city",
        country: "address_arrive_country",
        postal_code: "address_arrive_cp",
        lat: "address_arrive_lat",
        lng: "address_arrive_lng",
      },
    };

    const setAddress = (autocomplete, direction) => {
      var place = autocomplete.getPlace();
      quote['address_'+direction+'_lng'] = place.geometry.location.lng();
      quote['address_'+direction+'_lat'] = place.geometry.location.lat();
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];
        quote[mapping[direction][addressType]] = place.address_components[i].long_name;
      }
      quote[mapping[direction]['formatted_address']] = place.formatted_address;
    }

  return (
    <div className="pricing">
            <form className="needs-validation" onSubmit={handleSubmit}>
              <Autocomplete lang={props.lang} setAddress={setAddress}/>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="voyage">{translations[props.lang].voyage.label}</label>
                  <select
                    className="custom-select d-block w-100"
                    id="voyage"
                    required=""
                    name="voyage"
                    value={quote.voyage || ""} 
                    onChange={handleChange}
                  >
                    {translations[props.lang].voyage.options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="date">{translations[props.lang].startDate.label}</label>
                  <input
                    type="date"
                    name="date"
                    min={today}
                    id="date"
                    required
                    className="form-control"
                    value={quote.date || ""} 
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="hour">{translations[props.lang].startHour.label}</label>
                  <select
                    className="custom-select d-block w-100"
                    id="hour"
                    required=""
                    name="hour"
                    value={quote.hour || ""} 
                    onChange={handleChange}
                  >
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                  </select>
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="minute">{translations[props.lang].startMin.label}</label>
                  <select
                    className="custom-select d-block w-100"
                    id="minute"
                    required
                    name="minute"
                    value={quote.minute || ""} 
                    onChange={handleChange}
                  >
                    <option value="00">00</option>
                    <option value="05">05</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="35">35</option>
                    <option value="40">40</option>
                    <option value="45">45</option>
                    <option value="50">50</option>
                    <option value="55">55</option>
                  </select>
                </div>
              </div>
              {quote.voyage === 'return' &&
              <div id="return-row" className="row">
                <div className="col-md-4 mb-3"></div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="return_date">{translations[props.lang].toDate.label}</label>
                  <input
                    type="date"
                    name="return_date"
                    required
                    min={today}
                    id="return_date"
                    className="form-control"
                    value={quote.return_date || ""} 
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="return_hour">{translations[props.lang].toHour.label}</label>
                  <select
                    className="custom-select d-block w-100"
                    id="return_hour"
                    required
                    name="return_hour"
                    value={quote.return_hour || ""} 
                    onChange={handleChange}
                  >
                    <option value="00">00</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                  </select>
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="return_minute">{translations[props.lang].toMin.label}</label>
                  <select
                    className="custom-select d-block w-100"
                    id="return_minute"
                    required
                    name="return_minute"
                    value={quote.return_minute || ""} 
                    onChange={handleChange}
                  >
                    <option value="00">00</option>
                    <option value="05">05</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                    <option value="35">35</option>
                    <option value="40">40</option>
                    <option value="45">45</option>
                    <option value="50">50</option>
                    <option value="55">55</option>
                  </select>
                </div>
              </div>
              }
              <div className="row">
                <div className="col-md-4 mb-3">
                  <select
                    className="custom-select d-block w-100"
                    id="vehicule"
                    required
                    name="vehicule"
                    value={quote.vehicule || ""} 
                    onChange={handleChange}
                  >
                    {translations[props.lang].vehicule.options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <select
                    className="custom-select d-block w-100"
                    id="passenger"
                    required
                    name="passenger"
                    value={quote.passenger || ""} 
                    onChange={handleChange}
                  >
                    <option value="1">1 {translations[props.lang].passengers.singuliar}</option>
                    <option value="2">2 {translations[props.lang].passengers.plural}</option>
                    <option value="3">3 {translations[props.lang].passengers.plural}</option>
                    <option value="4">4 {translations[props.lang].passengers.plural}</option>
                    <option value="5">5 {translations[props.lang].passengers.plural}</option>
                    <option value="6">6 {translations[props.lang].passengers.plural}</option>
                    <option value="7">7 {translations[props.lang].passengers.plural}</option>
                    <option value="8">8 {translations[props.lang].passengers.plural}</option>
                    <option value="9">9 {translations[props.lang].passengers.plural}</option>
                    <option value="10">10 {translations[props.lang].passengers.plural}</option>
                    <option value="11">11 {translations[props.lang].passengers.plural}</option>
                    <option value="12">12 {translations[props.lang].passengers.plural}</option>
                    <option value="13">13 {translations[props.lang].passengers.plural}</option>
                    <option value="14">14 {translations[props.lang].passengers.plural}</option>
                    <option value="15">15 {translations[props.lang].passengers.plural}</option>
                    <option value="16">16 {translations[props.lang].passengers.plural}</option>
                    <option value="17">17 {translations[props.lang].passengers.plural}</option>
                    <option value="18">18 {translations[props.lang].passengers.plural}</option>
                    <option value="19">19 {translations[props.lang].passengers.plural}</option>
                    <option value="20">20 {translations[props.lang].passengers.plural}</option>
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <select
                    className="custom-select d-block w-100"
                    id="bagages"
                    required
                    name="bagages"
                    value={quote.bagages || ""} 
                    onChange={handleChange}
                  >
                    <option value="0">{translations[props.lang].bagages.no}</option>
                    <option value="1">1 {translations[props.lang].bagages.singuliar}</option>
                    <option value="2">2 {translations[props.lang].bagages.plural}</option>
                    <option value="3">3 {translations[props.lang].bagages.plural}</option>
                    <option value="4">4 {translations[props.lang].bagages.plural}</option>
                    <option value="5">5 {translations[props.lang].bagages.plural}</option>
                    <option value="6">6 {translations[props.lang].bagages.plural}</option>
                    <option value="7">7 {translations[props.lang].bagages.plural}</option>
                    <option value="8">8 {translations[props.lang].bagages.plural}</option>
                    <option value="9">9 {translations[props.lang].bagages.plural}</option>
                    <option value="10">10 {translations[props.lang].bagages.plural}</option>
                    <option value="11">11 {translations[props.lang].bagages.plural}</option>
                    <option value="12">12 {translations[props.lang].bagages.plural}</option>
                    <option value="13">13 {translations[props.lang].bagages.plural}</option>
                    <option value="14">14 {translations[props.lang].bagages.plural}</option>
                    <option value="15">15 {translations[props.lang].bagages.plural}</option>
                    <option value="16">16 {translations[props.lang].bagages.plural}</option>
                    <option value="17">17 {translations[props.lang].bagages.plural}</option>
                    <option value="18">18 {translations[props.lang].bagages.plural}</option>
                    <option value="19">19 {translations[props.lang].bagages.plural}</option>
                    <option value="20">20 {translations[props.lang].bagages.plural}</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3 pr-md-1">
                  <div className="form-label-group">
                    <input
                      type="text"
                      className="form-control"
                      id="firstname"
                      name="firstname"
                      placeholder="Prénom"
                      required
                      value={quote.firstname || ""} 
                      onChange={handleChange}   
                    />
                    <label htmlFor="firstname">{translations[props.lang].firstname.label}</label>
                  </div>
                </div>
                <div className="col-md-6 mb-3 pl-md-1">
                  <div className="form-label-group">
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      name="lastname"
                      placeholder="Nom"
                      required
                      value={quote.lastname || ""} 
                      onChange={handleChange}              
                    />
                    <label htmlFor="lastname">{translations[props.lang].lastname.label}</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3 pr-md-1">
                  <div className="form-label-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      required
                      value={quote.email || ""} 
                      onChange={handleChange}   
                    />
                    <label htmlFor="email">{translations[props.lang].email.label}</label>
                  </div>
                </div>
                <div className="col-md-6 mb-3 pl-md-1">
                  <div className="form-label-group">
                    <input
                      type="phone"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      value={quote.phone || ""} 
                      onChange={handleChange}   
                    />
                    <label htmlFor="phone">{translations[props.lang].phone.label}</label>
                  </div>
                </div>
              </div>
              <p id="FormError" className={styles.error}></p>
              <hr className="mb-4" />
              <button className="btn btn-lg btn-block" id="submit">
                {translations[props.lang].submit.label}
              </button>
            </form>
        <Recaptcha setToken={setMyToken} lang={props.lang}/>
    </div>
  );
};

export default QuoteForm;
