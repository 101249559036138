module.exports = {
    fr: {
        startPlace: {
            label: "Départ",
            placeholder: "Saisissez le lieu de prise en charge"
        },
        toPlace: {
            label: "Arrivé",
            placeholder: "Indiquez votre destination"
        },
        voyage: {
            label: "Voyage",
            options:[
                {value:"simple",label:"Aller simple"},
                {value:"return",label:"Aller / Retour"}
            ]
        },
        startDate: {
            label: "Date de départ"
        },
        startMin: {
            label: "Minutes"
        },
        startHour: {
            label: "Heure"
        },
        toDate: {
            label: "Date de retour"
        },
        toMin: {
            label: "Minutes"
        },
        toHour: {
            label: "Heure"
        },
        vehicule: {
            options:[
                {value:"berline",label:"Berline - 3 places"},
                {value:"van",label:"Van - 7 places"},
                {value:"minibus",label:"Minibus - 10+ places"}
            ]
        },
        passengers: {
            singuliar: "Passager",
            plural: "Passagers"
        },
        bagages: {
            no: "Pas de bagage",
            singuliar: "Bagage",
            plural: "Bagages"
        },
        firstname: {
            label: "Prénom"
        },
        lastname: {
            label: "Nom"
        },
        email: {
            label: "Email"
        },
        phone: {
            label: "Téléphone"
        },
        submit: {
            label: "Obtenir un devis gratuitement"
        },
        error: {
            recaptcha: "Erreur de Recaptcha, veuillez réessayer ou rafraichir la page.",
            form: "Erreur lors de la création de votre demande, veuillez réessayer ou rafraichir la page."
        }
    },
    en: {
        startPlace: {
            label: "Departure",
            placeholder: "Enter the place of care"
        },
        toPlace: {
            label: "Arrival",
            placeholder: "Indicate your destination"
        },
        voyage: {
            label: "Trip",
            options:[
                {value:"simple",label:"One way"},
                {value:"return",label:"Return"}
            ]
        },
        startDate: {
            label: "Date of departure"
        },
        startMin: {
            label: "Minutes"
        },
        startHour: {
            label: "Hour"
        },
        toDate: {
            label: "Return date"
        },
        toMin: {
            label: "Minutes"
        },
        toHour: {
            label: "Hour"
        },
        vehicule: {
            options:[
                {value:"berline",label:"Sedan - 4 seats"},
                {value:"van",label:"Van - 7 seats"},
                {value:"minibus",label:"Minibus - 10+ seats"}
            ]
        },
        passengers: {
            singuliar: "Passenger",
            plural: "Passengers"
        },
        bagages: {
            no: "No luggage",
            singuliar: "Luggage",
            plural: "Luggages"
        },
        firstname: {
            label: "First Name"
        },
        lastname: {
            label: "Last Name"
        },
        email: {
            label: "Email"
        },
        phone: {
            label: "Phone"
        },
        submit: {
            label: "Submit the quote request"
        },
        error: {
            recaptcha: "Recaptcha error, please try again or refresh the page.",
            form: "We could't create your request, please try again or refresh the page."
        }
    },
    ru: {
        startPlace: {
            label: "выезд",
            placeholder: "Введите место помощи"
        },
        toPlace: {
            label: "прибывший",
            placeholder: "Укажите свой пункт назначения"
        },
        voyage: {
            label: "путешествие",
            options:[
                {value:"simple",label:"Один способ"},
                {value:"return",label:"Вернуть"}
            ]
        },
        startDate: {
            label: "Дата отъезда"
        },
        startMin: {
            label: "минут"
        },
        startHour: {
            label: "время"
        },
        toDate: {
            label: "Дата возвращения"
        },
        toMin: {
            label: "минут"
        },
        toHour: {
            label: "время"
        },
        vehicule: {
            options:[
                {value:"berline",label:"Седан - 4 места"},
                {value:"van",label:"Ван - 7 мест"},
                {value:"minibus",label:"Микроавтобус - 10+ мест"}
            ]
        },
        passengers: {
            singuliar: "пассажир",
            plural: "пассажиров"
        },
        bagages: {
            no: "Нет багажа",
            singuliar: "багаж",
            plural: "багаж"
        },
        firstname: {
            label: "имя"
        },
        lastname: {
            label: "фамилия"
        },
        email: {
            label: "электронная почта"
        },
        phone: {
            label: "телефон"
        },
        submit: {
            label: "Submit the quote request"
        },
        error: {
            recaptcha: "Recaptcha error, please try again or refresh the page.",
            form: "We could't create your request, please try again or refresh the page."
        }
    }
};






