module.exports = {
  fr: {
    title: "Paiement",
    amount: "Montant à régler",
    cta: "Confirmer le paiement",
    terms:
      "En appuyant sur Confirmer le paiement, vous acceptez nos Conditions générales de vente.",
  },
  en: {
    title: "Paiement",
    amount: "Montant à régler",
    cta: "Confirmer le paiement",
    terms:
      "En appuyant sur Confirmer le paiement, vous acceptez nos Conditions générales de vente.",
  },
  ru: {
    title: "Paiement",
    amount: "Montant à régler",
    cta: "Confirmer le paiement",
    terms:
      "En appuyant sur Confirmer le paiement, vous acceptez nos Conditions générales de vente.",
  },
};
