module.exports = {
    fr: {
        title: "Déplacez-vous avec Davoyage, votre chauffeur VTC à Lyon.",
        description:"Davoyage Chauffeur Privé est une entreprise de location de voitures avec chauffeur basée à Lyon, en France. Réservation en ligne ou par téléphone 24h/24 et 7j/7.",
        canonical: "/",
        alternates: [
            {href: "/",hreflang: "fr"},
            {href: "/en/",hreflang: "en"},
            {href: "/ru/",hreflang: "ru"}
        ]
    },
    en: {
        title: "Ride with Davoyage, your private driver in France.",
        description:"Davoyage Private Driver is a car rental with driver company based in Lyon, France.",
        canonical: "/en/",
        alternates: [
            {href: "/",hreflang: "fr"},
            {href: "/en/",hreflang: "en"},
            {href: "/ru/",hreflang: "ru"}
        ]
    },
    ru: {
        title: "Поездка с Davoyage, вашим личным водителем во Франции.",
        description:"Davoyage Private Driver - это прокат автомобилей с компанией-водителем в Лионе, Франция.",
        canonical: "/ru/",
        alternates: [
            {href: "/",hreflang: "fr"},
            {href: "/en/",hreflang: "en"},
            {href: "/ru/",hreflang: "ru"}
        ]
    }
};