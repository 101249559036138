import React from "react";
import { useSearchParams } from "react-router-dom";

import { Helmet } from 'react-helmet';

import Steps from "../components/steps/Steps";
import QuoteForm from "../components/quoteForm/QuoteForm";
import translations from "../translations/quote-form";

const Quote = (props) => {

  let [searchParams] = useSearchParams()
    , service = searchParams.get("service")
  ;

  let urlPath = props.lang === 'fr' ? '' : '/' + props.lang;

  return (
    <div className="quote">
      <Helmet>
        <link rel="canonical" href={"https://www.davoyage.com" + urlPath + "/devis-reservation-vtc/"} />
      </Helmet>
      <Steps active="0"/>
      <div className="container bg-lights">
        <div className="pt-5 pb-4 text-center">
          <h1>{translations[props.lang].title}</h1>
          <p className="lead">
            {translations[props.lang].description}{" "}
            <a href="mailto:contact@davoyage.com">contact@davoyage.com</a>.
          </p>
        </div>
        <div className="row">
          <div className="col-md-2 order-1"></div>
          <div className="col-md-8 order-2">
            <QuoteForm lang={props.lang} vehicule={service}/>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Quote;
