import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import config from "../../config";
import styles from './ArticlesList.module.css';

const ArticlesList = (props) => {
  
  const [articles, setArticles] = useState([]);
  const articlePath = props.lang === 'fr' ? '/blog/' : '/'+props.lang+'/blog/';

  useEffect(() => {
    axios
      .get(config["api-endpoint"] + "/articles?lang="+props.lang+"&limit="+props.limit, {
        headers: {
          "x-api-key": config['x-api-key'],
        },
      })
      .then((res) => {
        let filteredArticles = [];
        res.data.forEach((article) => {
          article.translations.forEach((translation) => {
            if (translation.lang === props.lang) {
              translation._id = article._id;

              const date = new Date(translation.createdAt);
              const year = date.getFullYear();
              const month = (date.getMonth()<9 ? "0"+ (date.getMonth()+1) : date.getMonth()+1);
              const day = (date.getDate()<10 ? "0"+date.getDate() : date.getDate());
        
              translation.createdAt = day+"/"+month+"/"+year;
  

              filteredArticles.push(translation);
            }
          });
        });
        setArticles(filteredArticles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.lang, props.limit]);

  return (
    <div className="album blog bg-light">
      <div className="container">
        <div className="row pt-3">
          {articles.map((article) => (
            <div className="col-md-4 col-sm-6" key={article._id}>
              <div className="card mb-4 box-shadow">
                <img
                  className={styles.img}
                  src={article.imageLink}
                  data-holder-rendered="true"
                  alt={article.title}
                  title={article.title}
                />
                <div className="card-body">
                  <p className="card-text">
                    <Link to={articlePath + article.slug} className="secondary">
                      {article.title}
                    </Link>
                  </p>
                  <small className="text-muted">Publié le {article.createdAt}</small>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticlesList;
