import React from "react";
import { Helmet } from 'react-helmet';
import ArticlesList from "../components/articlesList/ArticlesList";

import translations from "../translations/blog";

const Blogs = (props) => {
  return (
    <div className="album blog bg-light">
      <Helmet>
        <title>{translations[props.lang].meta.title}</title>
        <meta name="description" content={translations[props.lang].meta.description}/>
        <meta property="og:title" content={translations[props.lang].meta.title}/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={"https://www.davoyage.com/" + (props.lang==="fr"?"":props.lang+"/") + "blog/"}/>
        <meta property="og:image" content={translations[props.lang].og.image.url}/>
        <meta property="og:image:alt" content={translations[props.lang].og.image.alt}/>
        <meta property="og:description" content={translations[props.lang].meta.description}/>
        <meta property="og:site_name" content="Davoyage"/>
      </Helmet>
      <div className="container">
        <h1 className="pt-5 text-center pb-4 border-bottom">{translations[props.lang].head}</h1>
        <ArticlesList limit="0" lang={props.lang}/>
      </div>
    </div>
  );
};

export default Blogs;