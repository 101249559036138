module.exports = {
  fr: {
    about: {
      label: "À propos",
      cgv: {
        label: "Conditions Générales de Vente",
        url: "/conditions-generales-de-vente/",
      },
      privacy: {
        label: "Mentions Légales",
        url: "/mentions-legales/",
      },
    },
    prestations: {
      label: "Prestations",
      wedding: {
        label: "Location voiture de Mariage",
        url: "/blog/location-voiture-de-mariage-avec-chauffeur",
      },
      bus: {
        label: "Location de bus",
        url: "/blog/location-bus-minibus-avec-chauffeur-prive",
      },
      business: {
        label: "Déplacements d'affaires",
        url: "/blog/deplacements-d-affaires",
      },
      event: {
        label: "Événementiel",
        url: "/blog/evenementiel",
      },
      aeroport: {
        label: "Transfert aéroport",
        url: "/blog/transfert-aeroport",
      },
      vtclyon: {
        label: "VTC Lyon",
        url: "/blog/vtc-lyon",
      },
      ski: {
        label: "Transfert ski",
        url: "/blog/transfert-ski",
      },
      visite: {
        label: "Visite guidée",
        url: "/blog/visite-guidee",
      },
    },
    partners: {
      label: "Partenaires",
      atoutfrance: {
        label: "Atout France",
        url: "/blog/atout-france",
      },
      ffevtc: {
        label: "FFEVTC",
        url: "/blog/federation-francaise-des-exploitants-de-voiture-de-transport-avec-chauffeur",
      },
      onlylyon: {
        label: "ONLY LYON",
        url: "/blog/only-lyon",
      },
    },
    payment: {
      label: "Modes de paiement",
      visa: {
        label: "Visa",
        url: "https://www.visa.fr/",
      },
      mastercard: {
        label: "Master Card",
        url: "https://www.mastercard.fr/fr-fr.html",
      },
      amex: {
        label: "American Express",
        url: "https://www.americanexpress.com/fr/",
      },
      paypal: {
        label: "Paypal",
        url: "https://www.paypal.com/fr/home",
      },
      applepay: {
        label: "Apple Pay",
        url: "https://www.apple.com/fr/apple-pay/",
      },
      cash: {
        label: "Chèques et Espèces",
      },
    },
    translation: {
      label: "Traduction",
    },
    social: {
      label: "Réseaux Sociaux",
      facebook: {
        label: "Facebook",
        url: "https://www.facebook.com/davoyage.chauffeur.prive",
      },
      twitter: {
        label: "Twitter",
        url: "https://twitter.com/davoyage_vtc",
      },
      instagram: {
        label: "Instagram",
        url: "https://www.instagram.com/davoyage/",
      },
      linkedin: {
        label: "Linkedin",
        url: "https://www.linkedin.com/company/davoyage-chauffeur-privé/",
      },
    },
  },
  en: {
    about: {
      label: "About",
      cgv: {
        label: "Commercial Terms",
        url: "/en/conditions-generales-de-vente/",
      },
      privacy: {
        label: "Legal Notice",
        url: "/en/mentions-legales/",
      },
    },
    prestations: {
      label: "Offers",
      wedding: {
        label: "Wedding",
        url: "/en/blog/wedding-car-rental-with-driver",
      },
      bus: {
        label: "Bus rental",
        url: "/en/blog/bus-rental",
      },
      business: {
        label: "Business trips",
        url: "/en/blog/business-trips",
      },
      event: {
        label: "Events",
        url: "/en/blog/events",
      },
      aeroport: {
        label: "Airport transfer",
        url: "/en/blog/airport-transfer",
      },
      vtclyon: {
        label: "VTC Lyon",
        url: "/en/blog/chauffeured-car-rental-in-lyon",
      },
      ski: {
        label: "Ski transfer",
        url: "/en/blog/ski-transfer",
      },
      visite: {
        label: "Guided tour",
        url: "/en/blog/guided-tour",
      },
    },
    partners: {
      label: "Partners",
      atoutfrance: {
        label: "Atout France",
        url: "/en/blog/atout-france",
      },
      ffevtc: {
        label: "FFEVTC",
        url: "/en/blog/discover-the-french-federation-of-private-passenger-transport-companies-ffevtc",
      },
      onlylyon: {
        label: "ONLY LYON",
        url: "/en/blog/only-lyon",
      },
    },
    payment: {
      label: "Payment method",
      visa: {
        label: "Visa",
        url: "https://www.visa.com/",
      },
      mastercard: {
        label: "Master Card",
        url: "https://www.mastercard.fr/fr-fr.html",
      },
      amex: {
        label: "American Express",
        url: "https://www.americanexpress.com/fr/",
      },
      paypal: {
        label: "Paypal",
        url: "https://www.paypal.com/fr/home",
      },
      applepay: {
        label: "Apple Pay",
        url: "https://www.apple.com/fr/apple-pay/",
      },
      cash: {
        label: "Chèques et Espèces",
      },
    },
    translation: {
      label: "Translation",
    },
    social: {
      label: "Social Networks",
      facebook: {
        label: "Facebook",
        url: "https://www.facebook.com/davoyage.chauffeur.prive",
      },
      twitter: {
        label: "Twitter",
        url: "https://twitter.com/davoyage_vtc",
      },
      instagram: {
        label: "Instagram",
        url: "https://www.instagram.com/davoyage/",
      },
      linkedin: {
        label: "Linkedin",
        url: "https://www.linkedin.com/company/davoyage-chauffeur-privé/",
      },
    },
  },
  ru: {
    about: {
      label: "кстати",
      cgv: {
        label: "Общие условия продажи",
        url: "/ru/conditions-generales-de-vente/",
      },
      privacy: {
        label: "Юридические вопросы",
        url: "/ru/mentions-legales/",
      },
    },
    prestations: {
      label: "черты",
      wedding: {
        label: "брак",
        url: "/ru/blog/Прокат-свадебных-автомобилей-с-водителем",
      },
      bus: {
        label: "Прокат автобусов",
        url: "/ru/blog/Прокат-автобусов",
      },
      business: {
        label: "Деловые поездки",
        url: "/ru/blog/Деловые-поездки",
      },
      event: {
        label: "события",
        url: "/ru/blog/события",
      },
      aeroport: {
        label: "Трансфер из аэропорта",
        url: "/ru/blog/Трансфер-из-аэропорта",
      },
      vtclyon: {
        label: "VTC Lyon",
        url: "/ru/blog/vtc-lyon",
      },
      ski: {
        label: "Лыжный трансфер",
        url: "/ru/blog/Лыжный-трансфер",
      },
      visite: {
        label: "Экскурсия",
        url: "/ru/blog/Экскурсия",
      },
    },
    partners: {
      label: "партнеры",
      atoutfrance: {
        label: "Atout France",
        url: "/ru/blog/atout-france",
      },
      ffevtc: {
        label: "FFEVTC",
        url: "/ru/blog/frantsuzskaya-federatsiya-perevozchikov-naemnykh-transportnykh-sredstv-ffevtc",
      },
      onlylyon: {
        label: "ONLY LYON",
        url: "/ru/blog/only-lyon",
      },
    },
    payment: {
      label: "Способ оплаты",
      visa: {
        label: "Visa",
        url: "https://www.visa.com.ru/",
      },
      mastercard: {
        label: "Master Card",
        url: "https://www.mastercard.fr/fr-fr.html",
      },
      amex: {
        label: "American Express",
        url: "https://www.americanexpress.com/fr/",
      },
      paypal: {
        label: "Paypal",
        url: "https://www.paypal.com/fr/home",
      },
      applepay: {
        label: "Apple Pay",
        url: "https://www.apple.com/fr/apple-pay/",
      },
      cash: {
        label: "Чеки и наличные деньги",
      },
    },
    translation: {
      label: "перевод",
    },
    social: {
      label: "Социальные сети",
      facebook: {
        label: "Facebook",
        url: "https://www.facebook.com/davoyage.chauffeur.prive",
      },
      twitter: {
        label: "Twitter",
        url: "https://twitter.com/davoyage_vtc",
      },
      instagram: {
        label: "Instagram",
        url: "https://www.instagram.com/davoyage/",
      },
      linkedin: {
        label: "Linkedin",
        url: "https://www.linkedin.com/company/davoyage-chauffeur-privé",
      },
    },
  },
};
