import React from "react";

const Terms = (props) => {
  return (
    <div class="article">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Conditions Générales de Vente</h1>
            <div class="col-xs-12">
              <h2>Réservation</h2>
              <p>
                Une pré-réservation courrier électronique ou par téléphone est
                obligatoire pour toute prestation. La société enverra alors un
                devis au client. La réservation devient contractuelle lorsque le
                client a retourné le devis signé par courrier ou courrier
                électronique.
              </p>
              <p>
                Adresse électronique : david.imirzian@davoyage.com <br />
                Téléphone : +33 6 60 26 53 36
              </p>
              <p>
                Un acompte de 25% minimum du cout de la prestation pourra être
                demandé lors de la réservation si celle-ci est jugée importante
                par le chauffeur. Le solde sera alors à régler avant le départ
                du véhicule.
              </p>
              <p>
                La société se réserve le droit d’interrompre ou d’annuler une
                réservation en cas de forces majeurs.
              </p>
              <h2>Responsabilité</h2>
              <p>
                Le transporteur ne pourra être tenu responsable des préjudices
                causés par la densité du trafic routier.
              </p>
              <p>
                Le chauffeur est tenu de respecter le code de la route. Le
                client ne peut demander au chauffeur d’enfreindre le code de la
                route. Le port de la ceinture est obligatoire. Le chauffeur ne
                pourra être tenu responsable des conséquences du non port de la
                ceinture (contravention, accident).
              </p>
              <p>
                Le véhicule est non-fumeur. Le chauffeur pourra s’arrêter durant
                le trajet pour permettre au client de fumer à l’extérieur du
                véhicule.
              </p>
              <p>
                Il est strictement interdit de consommer de l’alcool ou tout
                autres stupéfiants à bord du véhicule.
              </p>
              <p>
                Le respect du matériel est exigé : toute dégradation de
                l’équipement sera facturée. La dégradation de l’équipement
                intérieur ou autres dommages infligés au véhicule ou à d’autres
                véhicules sera à la charge du client.
              </p>
              <p>
                Seul le chauffeur doit ouvrir la porte. Toute ouverture
                intempestive entraînant des dégâts sur le véhicule sera à la
                charge du client.
              </p>
              <p>
                Les changements de dernières minutes ou demandes de modification
                de trajet peuvent être refusés par la société ou conduire à une
                modification de tarif.
              </p>
              <p>Toute prestation commencée est due.</p>
              <p>
                En cas de panne, la facture sera calculée au pro rata de la
                prestation effectuée. Le chauffeur ne pourra être tenu
                responsable des préjudices causés par la panne.
              </p>
              <h2>Tarifs</h2>
              <p>
                Les prix sont fixés avant la prestation et indépendants du
                trafic routier.
              </p>
              <p>
                Tous frais supplémentaires (péages, parking, autres) non
                convenus d’avance seront facturés en supplément au client.
              </p>
              <p>
                Une majoration tarifaire de 30% sera appliquée pour toutes
                prestations ayant lieu entre 20h00 et 07h00 du matin.
              </p>
              <h2>Paiement</h2>
              <p>
                Les modes de paiement acceptés sont : espèce, carte bancaire
                (Visa, Master Card) et virement bancaire.
              </p>
              <h2>Annulation</h2>
              <p>
                Annulation 72h avant : sans frais
                <br />
                Annulation de 24h à 48h avant : 50% de la prestation
                <br />
                Annulation de l’heure de réservation à 24h avant : 100% de la
                prestation
              </p>
              <p>
                Les réservations non annulées après le départ du véhicule seront
                facturées au tarif en vigueur.
              </p>
              <h2>Bagages</h2>
              <p>
                Les bagages ou toute autre propriété du client restent sous sa
                responsabilité. Notre société ne pourra pas être tenu
                responsable en cas de vols ou de pertes.
              </p>
              <p>
                La société accepte 20kg de bagage par personne. En cas de
                dépassement, veuillez en informer le chauffeur lors de la
                réservation. Dans le cas contraire, nous nous réservons le droit
                de facturer un supplément pour les bagages hors-limites.
              </p>
              <p>
                Si un bagage venait à détériorer le véhicule, le client sera
                responsable des réparations.
              </p>
              <h2>Animaux</h2>
              <p>
                Aucun animal n’est accepté dans le véhicule, sauf dans une cage
                ou en conteneur hermétique prévu à cet effet et approuvé par le
                chauffeur.
              </p>
              <h2>Interruption de la prestation</h2>
              <p>
                Le chauffeur se réserve le droit d’interrompre la course s’il
                juge que le comportement des passagers est contraire aux lois en
                vigueur ou insultant à son égard.
              </p>
              <p>
                Le chauffeur se réserve le droit d’interrompre la course si le
                passager met en péril la sécurité du chauffeur ou du véhicule.
              </p>
              <p>
                Aucun remboursement ou dédommagement ne pourra alors être exigé.
              </p>
              <h2>TVA</h2>
              <p>Les taux en vigueur seront appliqués.</p>
              <p>
                Pour les transferts, le taux TVA est de 10%. Un transfert se
                définit comme le déplacement d’un point A à un point B.
              </p>
              <p>
                Pour la mise à disposition, le taux TVA est de 20%. Une mise à
                disposition se définit comme un déplacement personnalisé.
              </p>
              <h2>Litige</h2>
              <p>
                En cas de litige, celui-ci sera soumis à la seule compétence du
                tribunal de Lyon.
              </p>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
