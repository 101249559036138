import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import translations from "./Checkout-form.translations";
import styles from "./Checkout-form.module.css";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const url = window.location;
    const path =
      (props.lang === "fr" ? "" : "/" + props.lang) +
      "/devis-reservation-vtc/success";
    console.log(url.protocol + "//" + url.hostname + ":" + url.port + path);

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: url.protocol + "//" + url.hostname + ":" + url.port + path,
      },
    });

    if (result.error) {
      console.log(result.error.message);
      const messageContainer = document.querySelector("#payment-message");
      messageContainer.textContent = result.error;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const enabledButton = async (event) => {
    document.getElementById("submit").disabled = false;
  };

  return (
    <div className="row mt-3 pb-5">
      <div className="col-md-2"></div>
      <div className="col-md-8 col-sm-12">
        <div className="card mt-3">
          <div className="card-header">{translations[props.lang].title}</div>
          <form
            onSubmit={handleSubmit}
            id="payment-form"
            className={styles.form}
          >
            <p>
              {translations[props.lang].amount}{" "}
              <strong>{props.quote.price}€</strong>
            </p>
            <div id="payment-element" className="StripeElement">
              <PaymentElement onReady={enabledButton} />
            </div>
            <button
              id="submit"
              className={"btn btn-primary mb-2 mt-4 " + styles.btn}
              disabled
            >
              <div className="spinner hidden" id="spinner"></div>
              <span id="button-text">{translations[props.lang].cta}</span>
            </button>
            <div id="payment-message"></div>
            <p>
              <small>{translations[props.lang].terms}</small>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
