import React, { useEffect, useState } from "react";

import translations from "../quoteForm/quote-form-translations";
import config from "../../config";

const AutoComplete = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (window.google) return;
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src =
      "https://maps.googleapis.com/maps/api/js?callback=Function.prototype&libraries=places&key=" +
      config["google-map-key"];
    script.addEventListener("load", ()=>setLoaded(true));
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if(!loaded && !window.google) return;
    const google = window.google;

    const geolocation = {
      lat: 45.7234181,
      lng: 5.088776800000005,
    };

    const circle = new google.maps.Circle({
      center: geolocation,
      radius: 100,
    });

    const options = {
      componentRestrictions: { country: ["fr", "ch"] },
    };

    let addressDepart = document.getElementById("address-depart");
    let autocomplete_from = new google.maps.places.Autocomplete(
      addressDepart,
      options
    );
    autocomplete_from.addListener("place_changed", function () {
        props.setAddress(autocomplete_from,'depart');
    });
    addressDepart.addEventListener("change", function () {
      addressDepart.value = null;
    });
    autocomplete_from.setBounds(circle.getBounds());

    let addressarrive = document.getElementById("address-arrive");
    let autocomplete_to = new google.maps.places.Autocomplete(
      addressarrive,
      options
    );
    autocomplete_to.addListener("place_changed", function () {
        props.setAddress(autocomplete_to,'arrive');
    });
    addressarrive.addEventListener("change", function () {
      addressarrive.value = null;
    });
    autocomplete_to.setBounds(circle.getBounds());
  }, [loaded,props]);

  return (
    <div>
      <div className="mb-3">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text trajet">
              {translations[props.lang].startPlace.label}
            </span>
          </div>
          <input
            type="text"
            className="form-control pac-target-input"
            id="address-depart"
            name="address_depart"
            placeholder={translations[props.lang].startPlace.placeholder}
            required
            autoComplete="off"
          />
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text trajet">
              {translations[props.lang].toPlace.label}
            </span>
          </div>
          <input
            type="text"
            className="form-control pac-target-input"
            id="address-arrive"
            name="address_arrive"
            placeholder={translations[props.lang].toPlace.placeholder}
            required
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
};

export default AutoComplete;
