import React from "react";

import translations from "../translations/error";

const NoPage = (props) => {
    return (
      <div className="error">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{translations[props.lang].body.title}</h1>
              <br/>
              <p>{translations[props.lang].body.text}</p>
              <p><a href={translations[props.lang].body.url}>{translations[props.lang].body.label}</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default NoPage;
  