module.exports = {
  fr: {
    title: "Consultez notre blog",
    subtitle: "Nos clients et partenaires sont à l'honneur sur le blog. Découvrez également les événements couverts par nos chauffeurs privés.",
  },
  en: {
    title: "Visit our blog",
    subtitle: "Our customers and partners are honored on the blog. Also discover the events covered by our private drivers.",
  },
  ru: {
    title: "Посмотрите наш блог",
    subtitle: "Наши клиенты и партнеры выполняются в блоге. Также ознакомьтесь с событиями, которые распространяются нашими частными драйверами.",
  }
};
