import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';

import Steps from "../components/steps/Steps";
import QuoteDetail from "../components/quoteDetail/QuoteDetail";
import translations from "../translations/confirmation";

const Confirmation = (props) => {

  let location = useLocation();
  let navigate = useNavigate();

  if(location.state==null){
    console.log(location.state, 'redirecting')
    return navigate((props.lang==='fr'?'':'/'+props.lang)+"/devis-reservation-vtc/");
  }

  let quote = location.state;

  var domain = quote.email.split("@")[1][0];
  var dI = quote.email.split("@")[1].split(".")[0].length;
  for (var i = 0; i < dI - 1; i++) {
    domain += "*";
  }
  domain += "." + quote.email.split("@")[1].split(".")[1];
  var user = quote.email.split("@")[0][0];
  var uI = quote.email.split("@")[0].length;
  for (var j = 0; j < uI - 1; j++) {
    user += "*";
  }
  quote.emailObs = user + "@" + domain;

  return (
    <div className="quote">
      <Helmet>
        <title>{translations[props.lang].meta.title}</title>
        <meta name="description" content={translations[props.lang].meta.description}/>
      </Helmet>
      <Steps active="1" />
      <div className="container bg-lights">
        <div className="pt-5 pb-4">
          <h2 className="text-center">{translations[props.lang].head}</h2>
          <br />
          <p
            className="lead text-center"
            dangerouslySetInnerHTML={{
              __html: translations[props.lang].subhead + " " + quote.emailObs,
            }}
          ></p>
           <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 col-sm-12">
            {quote !== undefined &&
              quote !== null &&
              quote.hasOwnProperty("emailObs") && (
                <QuoteDetail lang={props.lang} quote={quote} />
              )}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
