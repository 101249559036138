import React, { useEffect } from "react";

import Album from "../components/album/Album";
import Pricing from "../components/pricing/Pricing";

import translations from "../translations/home";

const Home = (props) => {

  useEffect(() => {
    document.title = translations[props.lang].title;
  }, [props.lang]);

  return (
    <div>
      <img
        className="card-img-top"
        src="https://res.cloudinary.com/hfguibnid/c_scale,w_auto,dpr_auto/mercedes-class-v-int.jpg"
        alt="Votre chauffeur privé"
        title="Votre chauffeur privé"
      ></img>
      <Pricing lang={props.lang} />
      <img
        className="card-img-top"
        src="https://res.cloudinary.com/hfguibnid/c_scale,w_auto,dpr_auto/mercedes-class-v-ext.jpg"
        alt="Mercedes Classe V"
        title="Mercedes Classe V"
      ></img>
      <Album lang={props.lang} />
    </div>
  );
};

export default Home;