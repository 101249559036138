module.exports = {
    fr: {
        meta: {
            title: "Finalisez votre réservation"
        },
        title: "Finalisez votre réservation",
    },
    en: {
        meta: {
            title: "Finalisez votre réservation"
        },
        title: "Finalisez votre réservation",
    },
    ru: {
        meta: {
            title: "Finalisez votre réservation"
        },
        title: "Finalisez votre réservation",
    }
};