import React from "react";

import ArticlesList from "../articlesList/ArticlesList";
import translations from "./album-translations";

const Pricing = (props) => {
  return (
    <div className="album">
      <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h2 className="display-4">{translations[props.lang].title}</h2>
        <p className="lead">{translations[props.lang].subtitle}</p>
      </div>
      <ArticlesList limit="9" lang={props.lang}/>
    </div>
  );
};

export default Pricing;
