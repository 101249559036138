module.exports = {
  fr: {
    head: "Découvrez un service de transport de qualité, sans stress ni tracas",
    subtitle:"Notre service de chauffeur privé Davoyage est conçu pour offrir une expérience de transport de qualité supérieure à tous nos clients. Nous nous engageons à fournir un service fiable, ponctuel et sécurisé, tout en éliminant le stress et les tracas souvent associés aux déplacements en ville.",
    lead: "Que vous ayez besoin d'un transport pour vos rendez-vous d'affaires, vos soirées en ville ou simplement pour vous déplacer dans le confort et le style, nous sommes là pour répondre à tous vos besoins de transport privé.",
    berline: {
      name: "Berline",
      number: "3",
      attribute: "places",
      options: [
        "Mercedes Classe E / S",
        "Jusqu'à 3 valises",
        "Presse • Rafraichissement • Wifi",
        "",
      ],
      cta: {
        label: "Demander un devis",
        link: "/devis-reservation-vtc/?service=berline",
      },
    },
    van: {
      name: "Van",
      number: "7",
      attribute: "places",
      options: [
        "Mercedes Classe V / Viano",
        "Jusqu'à 8 valises",
        "Presse • Rafraichissement • Wifi",
        "Guide touristique professionnel",
      ],
      cta: {
        label: "Demander un devis",
        link: "/devis-reservation-vtc/?service=van",
      },
    },
    minibus: {
      name: "Minibus",
      number: "15+",
      attribute: "places",
      options: [
        "Mercedes Sprinter",
        "15+ valises",
        "Presse • Rafraichissement • Wifi",
        "Guide touristique professionnel",
      ],
      cta: {
        label: "Demander un devis",
        link: "/devis-reservation-vtc/?service=minibus",
      },
    },
  },
  en: {
    head: "Order your ride",
    subtitle:
      "Our private driver service Davoyage is designed to offer a superior transportation experience to all our clients. We are committed to providing a reliable, punctual, and secure service, while eliminating the stress and hassle often associated with city travel.",
    lead: "Whether you need transportation for your business meetings, nights out on the town, or simply to travel in comfort and style, we're here to meet all your private transportation needs.",
    berline: {
      name: "Sedan",
      number: "3",
      attribute: "seats",
      options: [
        "Mercedes Classe E / S",
        "Up to 3 suitcases",
        "Press • Refreshments • Wifi",
        "",
      ],
      cta: {
        label: "Ask for a quote",
        link: "/en/devis-reservation-vtc/?service=berline",
      },
    },
    van: {
      name: "Van",
      number: "7",
      attribute: "seats",
      options: [
        "Mercedes Classe V / Viano",
        "Up to 8 suitcases",
        "Press • Refreshments • Wifi",
        "Professional tour guide",
      ],
      cta: {
        label: "Ask for a quote",
        link: "/en/devis-reservation-vtc/?service=van",
      },
    },
    minibus: {
      name: "Minibus",
      number: "15+",
      attribute: "seats",
      options: [
        "Mercedes Sprinter",
        "15+ suitcases",
        "Press • Refreshments • Wifi",
        "Professional tour guide",
      ],
      cta: {
        label: "Ask for a quote",
        link: "/en/devis-reservation-vtc/?service=minibus",
      },
    },
  },
  ru: {
    head: "Закажите свою поездку",
    subtitle:
      "Наша услуга частного водителя Davoyage предназначена для предоставления превосходного опыта транспортировки всем нашим клиентам. Мы обязуемся предоставлять надежный, пунктуальный и безопасный сервис, одновременно устраняя стресс и хлопоты, часто связанные с городскими поездками.",
    lead: "Будь то транспортировка на деловые встречи, вечера в городе или просто передвижение с комфортом и стилем, мы готовы удовлетворить все ваши потребности в частном транспорте.",
    berline: {
      name: "седан",
      number: "3",
      attribute: "пассажир",
      options: [
        "Mercedes Classe E / S",
        "До 3 чемоданов",
        "Presse • Rafraichissement • Wifi",
        "",
      ],
      cta: {
        label: "Запросить предложение",
        link: "/ru/devis-reservation-vtc/?service=berline",
      },
    },
    van: {
      name: "фургон",
      number: "7",
      attribute: "пассажир",
      options: [
        "Mercedes Classe V / Viano",
        "До 8 чемоданов",
        "Presse • Rafraichissement • Wifi",
        "Профессиональный гид",
      ],
      cta: {
        label: "Запросить предложение",
        link: "/ru/devis-reservation-vtc/?service=van",
      },
    },
    minibus: {
      name: "микроавтобус",
      number: "15+",
      attribute: "пассажир",
      options: [
        "Mercedes Sprinter",
        "15+ чемоданов",
        "Presse • Rafraichissement • Wifi",
        "Профессиональный гид",
      ],
      cta: {
        label: "Запросить предложение",
        link: "/ru/devis-reservation-vtc/?type=minibus",
      },
    },
  },
};
