import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Article from "./pages/ArticleDetail";
import Quote from "./pages/Quote";
import Confirmation from "./pages/Confirmation";
import Paiement from "./pages/Paiement";
import PaiementSuccess from "./pages/PaiementSuccess";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import NoPage from "./pages/NoPage";
import reportWebVitals from './reportWebVitals';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout lang={`fr`} />}>
          <Route index element={<Home lang={`fr`} />}/>
          <Route path="/blog/" element={<Blogs lang={`fr`} />} />
          <Route path="/blog/:slug" element={<Article lang={`fr`} />} />
          <Route path="/devis-reservation-vtc/" element={<Quote lang={`fr`} />} />
          <Route path="/devis-reservation-vtc/confirmation" element={<Confirmation lang={`fr`} />} />
          <Route path="/devis-reservation-vtc/paiement" element={<Paiement lang={`fr`} />} />
          <Route path="/devis-reservation-vtc/success" element={<PaiementSuccess lang={`fr`} />} />
          <Route path="/conditions-generales-de-vente/" element={<Terms lang={`fr`}/>} />
          <Route path="/mentions-legales/" element={<Privacy lang={`fr`}/>} />
          <Route path="*" element={<NoPage lang={`fr`}/>} />
        </Route>
        <Route path="/en/" element={<Layout lang={`en`}/>}>
          <Route index element={<Home lang={`en`}/>} />        
          <Route path="/en/blog/" element={<Blogs lang={`en`}/>} />
          <Route path="/en/blog/:slug" element={<Article lang={`en`}/>} />
          <Route path="/en/devis-reservation-vtc/" element={<Quote lang={`en`}/>} />
          <Route path="/en/devis-reservation-vtc/confirmation" element={<Confirmation lang={`en`} />} />
          <Route path="/en/devis-reservation-vtc/paiement" element={<Paiement lang={`en`} />} />
          <Route path="/en/devis-reservation-vtc/success" element={<PaiementSuccess lang={`en`} />} />
          <Route path="/en/conditions-generales-de-vente/" element={<Terms lang={`en`}/>} />
          <Route path="/en/mentions-legales/" element={<Privacy lang={`en`}/>} />
          <Route path="*" element={<NoPage lang={`en`}/>} />
        </Route>
        <Route path="/ru/" element={<Layout lang={`ru`}/>}>
          <Route index element={<Home lang={`ru`}/>} />        
          <Route path="/ru/blog/" element={<Blogs lang={`ru`}/>} />
          <Route path="/ru/blog/:slug" element={<Article lang={`ru`}/>} />
          <Route path="/ru/devis-reservation-vtc/" element={<Quote lang={`ru`}/>} />
          <Route path="/ru/devis-reservation-vtc/confirmation" element={<Confirmation lang={`ru`} />} />
          <Route path="/ru/devis-reservation-vtc/paiement" element={<Paiement lang={`ru`} />} />
          <Route path="/ru/devis-reservation-vtc/success" element={<PaiementSuccess lang={`ru`} />} />
          <Route path="/ru/conditions-generales-de-vente/" element={<Terms lang={`ru`}/>} />
          <Route path="/ru/mentions-legales/" element={<Privacy lang={`ru`}/>} />
          <Route path="*" element={<NoPage lang={`ru`}/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();