import React from "react";

import Steps from "../components/steps/Steps";
import translations from "../translations/paiement-success";

const PaiementSuccess = (props) => {
  return (
    <div className="quote">
      <Steps active="3"/>
      <div className="container">
        <div className="pt-4 pb-2 text-center">
          <h2>{translations[props.lang].title}</h2>
          <p className="lead">{translations[props.lang].subtitle}</p>
          <p className="lead">
            {translations[props.lang].description}
            <br />
            <br />
            {translations[props.lang].soon}
          </p>
          <br />
          <p>
            <a href={translations[props.lang].home.link}>{translations[props.lang].home.label}</a>
          </p>
          <br />
        </div>
      </div>
      <img
        className="card-img-top"
        src="https://res.cloudinary.com/hfguibnid/c_scale,w_auto,dpr_auto/mercedes-class-v-int_old.jpg"
        alt="Successful reservation"
      />
    </div>
  );
};

export default PaiementSuccess;