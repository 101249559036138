import React from "react";

import translations from "./quote-detail-translations";

const Confirmation = (props) => {
  
  let fromDate = new Date(props.quote.date)
    , fromYear = fromDate.getFullYear()
    , fromMonth = fromDate.getMonth() < 10 ? '0'+fromDate.getMonth() : fromDate.getMonth()
    , fromDay = fromDate.getDate() < 10 ? '0'+fromDate.getDate() : fromDate.getDate() 
    , toYear
    , toMonth
    , toDay
  ;

  if(props.quote.voyage === 'return'){
    let toDate = new Date(props.quote.return_date);
    toYear = toDate.getFullYear()
    toMonth = toDate.getMonth() < 10 ? '0'+toDate.getMonth() : toDate.getMonth()
    toDay = toDate.getDate() < 10 ? '0'+toDate.getDate() : toDate.getDate() 
  ;
  }
  
  return (
    <div className="card mt-3">
      <div className="card-header">{translations[props.lang].summary}</div>
      <ul className="list-group list-group-flush">
        {props.quote.id !== undefined &&
        <li className="list-group-item">
          <strong>{translations[props.lang].title}</strong>
          <br />
          DEV-00{props.quote.id}
        </li>
        }
        <li className="list-group-item">
          <strong>{translations[props.lang].startDate}</strong>
          <br />
          Le {fromDay}/{fromMonth}/{fromYear} à {props.quote.hour}:{props.quote.minute}
        </li>
        <li className="list-group-item">
          <strong>{translations[props.lang].startPlace}</strong>
          <br />
          {props.quote.address_depart}
        </li>
        <li className="list-group-item">
          <strong>{translations[props.lang].toPlace}</strong>
          <br />
          {props.quote.address_arrive}
        </li>
        {props.quote.voyage === 'return' &&
          <li className="list-group-item">
            <strong>{translations[props.lang].toDate}</strong>
            <br />
            Le {toDay}/{toMonth}/{toYear} à {props.quote.return_hour}:{props.quote.return_minute}
          </li>
        }
        <li className="list-group-item">
          <strong>{translations[props.lang].passengers}</strong> {props.quote.passenger}
        </li>
        <li className="list-group-item">
          <strong>{translations[props.lang].bagages}</strong> {props.quote.bagages}
        </li>
        {props.quote.price !== undefined &&
        <li className="list-group-item">
          <strong>{translations[props.lang].price}</strong> {props.quote.price}€
        </li>
        }
      </ul>
    </div>
  );
};

export default Confirmation;