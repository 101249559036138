module.exports = {
  fr: {
    meta: {
      title: "Page Introuvable",
    },
    body: {
      title: "Page Introuvable",
      text: "Nous sommes désolés pour la gêne occasionnée.",
      label: "Revenir sur le site internet",
      url: "/",
    }
  },
  en: {
    meta: {
        title: "Page not found"
    },
    body: {
        title: "Page not found",
        text: "Sorry for the inconvenience.",
        label: "Return to the website",
        url: "/en/"
    }
  },
  ru: {
    meta: {
        title: "Страница не найдена"
    },
    body: {
        title: "Страница не найдена",
        text: "Мы сожалеем о неудобствах.",
        label: "Вернуться на сайт",
        url: "/ru/"
    }
  }
};
