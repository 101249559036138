import React from "react";

import { Link } from "react-router-dom";
import translations from "./pricing-translations";
import styles from './Pricing.module.css';

const Pricing = (props) => {
  return (
    <div className="pricing">
      <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className={styles.display4}>{translations[props.lang].head}</h1>
        <p className="lead">{translations[props.lang].subtitle}</p>
      </div>
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">
                {translations[props.lang].berline.name}
              </h4>
            </div>
            <div className="card-body">
              <h5 className="card-title pricing-card-title">
                {translations[props.lang].berline.number}{" "}
                <small className="text-muted">
                  {translations[props.lang].berline.attribute}
                </small>
              </h5>
              <ul className="list-unstyled mt-3 mb-4">
                {translations[props.lang].berline.options.map((option) => (
                  <li key={option}>{option}</li>
                ))}
              </ul>
              <Link
                to={translations[props.lang].berline.cta.link}
                className="btn btn-lg btn-block btn-primary"
              >
                {translations[props.lang].berline.cta.label}
              </Link>
            </div>
          </div>
          <div className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">
                {translations[props.lang].van.name}
              </h4>
            </div>
            <div className="card-body">
              <h5 className="card-title pricing-card-title">
                {translations[props.lang].van.number}{" "}
                <small className="text-muted">
                  {translations[props.lang].van.attribute}
                </small>
              </h5>
              <ul className="list-unstyled mt-3 mb-4">
                {translations[props.lang].van.options.map((option) => (
                  <li key={option}>{option}</li>
                ))}
              </ul>
              <Link
                to={translations[props.lang].van.cta.link}
                className="btn btn-lg btn-block btn-primary"
              >
                {translations[props.lang].van.cta.label}
              </Link>
            </div>
          </div>
          <div className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">Minibus</h4>
            </div>
            <div className="card-body">
              <h5 className="card-title pricing-card-title">
                {translations[props.lang].minibus.number}{" "}
                <small className="text-muted">
                  {translations[props.lang].minibus.attribute}
                </small>
              </h5>
              <ul className="list-unstyled mt-3 mb-4">
                {translations[props.lang].minibus.options.map((option) => (
                  <li key={option}>{option}</li>
                ))}
              </ul>
              <Link
                to={translations[props.lang].minibus.cta.link}
                className="btn btn-lg btn-block btn-primary"
              >
                {translations[props.lang].minibus.cta.label}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-header px-3 py-3 pb-md-4 mx-auto text-center">
        <p className="lead">{translations[props.lang].lead}</p>
      </div>
    </div>
  );
};

export default Pricing;
