import React, { useEffect, useState } from "react";

import config from "../../config";

const Recaptcha = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = "https://www.google.com/recaptcha/api.js?hl="+props.lang+"&render="+config['google-reCAPTCHA_site_key'];
    script.addEventListener("load", () => setLoaded(true));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      let grecaptchaBadge = document.getElementsByClassName('grecaptcha-badge')[0];
      grecaptchaBadge.parentNode.removeChild(grecaptchaBadge)
    };
  }, [props.lang]);

  useEffect(() => {
    if (!loaded) return;

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(config['google-reCAPTCHA_site_key'], { action: "quote" })
        .then(token => {
          props.setToken(token);
        });
    });
  }, [loaded, props]);

  return (
    <div
      className="g-recaptcha"
      data-sitekey={config['google-reCAPTCHA_site_key']}
      data-size="invisible"
    ></div>
  );
};

export default Recaptcha;