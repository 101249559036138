module.exports = {
    fr: {
        meta: {
            title: "Demander une estimation du prix",
            description: "Formulaire de réservation à remplir pour recevoir un devis.",    
        },
        head: "Nous avons bien reçu votre demande.",
        subhead: "Nous vous remercions pour votre confiance.<br/>Votre devis est en cours de préparation.<br/>Vous recevrez d'ici quelques minutes une proposition par email à votre adresse : ",
    },
    en: {
        meta: {
            title: "Request a price quote",
            description: "Reservation form to fill out to receive a quote.",    
        },
        head: "We successfully received your inquiry.",
        subhead: "We thank you for your confidence. <br/> Your quote is in preparation. <br/> You will receive within a few minutes a proposal by email to your address:",
    },
    ru: {
        meta: {
            title: "Запрос цены",
            description: "Форма резервирования для заполнения, чтобы получить котировку.",    
        },
        head: "Мы получили ваш запрос.",
        subhead: "Мы благодарим вас за доверие.<br/>Ваша цитата находится в стадии подготовки.<br/>Вы получите в течение нескольких минут предложение по электронной почте на ваш адрес:",

    }
};