import React from "react";

import PrivacyFR from "../components/privacy/Privacy-fr"
import PrivacyEn from "../components/privacy/Privacy-en"
import PrivacyRu from "../components/privacy/Privacy-ru"

const Privacy = (props) => {
  if(props.lang==='fr'){
    return (
      <PrivacyFR lang={props.lang}/>
    );
  } else if(props.lang==='en'){
    return (
      <PrivacyEn lang={props.lang}/>
    );
  } if(props.lang==='ru'){
    return (
      <PrivacyRu lang={props.lang}/>
    );
  }
};

export default Privacy;